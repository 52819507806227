
.about-section {
    display: block;
    background-color: rgb(247, 247, 247);
    text-align: center;
    overflow: hidden;
}

.about-section h1 {
    font-size: 15vw;
    color: rgba(255, 255, 255, 0.877);
    letter-spacing: 25px;
    padding-left: 25px;
}




.about-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: rgb(247, 247, 247);
    padding: 3rem 0 7rem 0;
}


.about-container {
    display: flex;
    justify-content: space-evenly;
    align-items: top;
    position: relative;
}

.about-container h2 {
    font-weight: 400;
    font-size: xx-large;
    letter-spacing: 4px;
    color: rgb(0, 127, 206);
    word-spacing: 5px;
}

.about-container h4 {
    font-weight: 100;
    word-spacing: 2px;
    color: rgb(60, 60, 60);
}

.about-container p, .about-container li {
    font-weight: 400;
    color: rgb(0, 0, 0);
    font-size: 18px;
}

.about-container ul {
    font-weight: 500;
    color: rgb(103, 103, 103);
}

.about-text {
    display: block;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
    padding: 3rem 6rem 3rem 3rem;
    border-radius: 15px;
    width: 40%;
    box-shadow: 0px 0px 50px 1px rgba(0, 0, 0, 0.103);
}

.interest-text {
    display: block;
    justify-content: center;
    align-items: center;  width: 100%;
    background-color: rgb(255, 255, 255);
    text-align: center;
    padding: 3rem 3rem 3rem 6rem;
    border-radius: 15px;
    width: 40%;
    box-shadow: 0px 0px 50px 1px rgba(0, 0, 0, 0.103);
}

.interest-text li {
    line-height: 25px;
    font-size: medium;
}

.photo-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.photo-container img {
    border-radius: 500px;
    border: 2px solid rgb(1, 91, 146);
    box-shadow: 0px 0px 50px 1px rgba(0, 0, 0, 0.21);
    transition: all ease-in-out 0.7s;
  
}



.photo-container2 {
    display: none;
}

.interest-text ul {
    list-style: inside;
}



@media screen and (max-width: 1000px) {

    
    .about-container {
        display: block;
        padding: 0;
    }
    .about-inner {
        padding: 0;
    }

    .about-container p {
        font-size: 20px;
    }
    .about {
        flex-wrap: wrap;
        padding: 0;
    }
    .photo-container {
        position: relative;
        display: none;
    }
    
    .photo-container2 {
        display: block;
    }
    
    .interest-text {
        border-radius: 0;
        box-shadow: none;
        width: 100%;
        padding: 3rem 1rem;
    }

    .about-text {
        width: 100%;
        padding: 2rem 2rem 3rem 2rem;
        border-radius: 0;
        box-shadow: none;
        padding: 3rem;
    }

    .about-text, .interest-text {
        background-color: rgb(247, 247, 247);
    }
}

@media screen and (max-width: 600px) {
    .about-container p, .interest-text li {
        font-size: smaller;
    }
    .about-text  {
        padding: 3rem 1rem;
    }

    

    
}