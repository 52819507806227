

.nav-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-width: 100%;
    min-height: 7vh;
    background-color: rgb(255, 255, 255);
    position: fixed;
    z-index: 10;
    overflow: hidden;
}
.nav-container ul {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.nav-container button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 30px;
    width: 120px;
    border: 1px solid rgb(0, 127, 206);
    list-style: none;
    box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.126);
    transition: all ease-in-out 0.2s;
}

.nav-container li {
    list-style: none;
}

.nav-container button:hover, .nav-container button:hover > a:visited {
    background-color: rgba(0, 157, 255, 0.397);
    color: rgb(255, 255, 255);
    scale: 1.1;
}





a:visited {
    color: rgb(0, 127, 206);
}
a:link {
    text-decoration: none;
    color: rgb(0, 127, 206);
}

@media  screen and (max-width: 750px) {
    .nav-container button {
        font-size: medium;
        border: none;
        width: 0;
        box-shadow: none;
        padding: 0;

    }
    .nav-container button {
        justify-content: space-around;
    }
}

@media  screen and (max-width: 750px) {
    .nav-container button {
        font-size: small;
    }
    
}
@media  screen and (max-width: 370px) {
    .nav-container button {
        font-size: xx-small;
    }
    
}