

.back-drop {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #00000000;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

