

.modal {
    margin: auto;
    padding: 0rem 2rem 2rem 2rem;
    border-radius: 30px;
    display: flex;
    background-color: rgba(0, 0, 0, 0.858);
    flex-direction: column;
    align-items: center;
    color: white;
    max-width: 80%;
    max-height: 90vh;
    overflow: hidden;
}



.close {
    font-size: 30px;
    padding: 1rem;
    margin: 5px;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    color: white;
}

.close-container {
    display: flex;
    justify-content: right;
    align-items: center;
    width: 100%;
}


.modal-buttons-div button {
    border: 1px solid rgb(0, 157, 255);
    background-color: rgba(0, 0, 0, 0);
    padding: 1rem;
    border-radius: 30px;
    margin: 20px;
    width: 250px;
    color: rgb(255, 255, 255);
    cursor: pointer;
    transition: all ease-in 0.1s;
    font-size: medium;
}

.modal p {
    font-size: large;
}

.modal-buttons-div button:hover {
    scale: 1.1;
    background: rgb(0, 157, 255);
    color: rgb(255, 255, 255);
    border: 1px solid white;
}





@media screen and (max-width: 1000px) {
    .modal p {
        font-size: small;
    }
}

@media screen and (max-width: 700px) {
    .view-buttons {
        width: 30vw;
    }
}
@media screen and (max-width: 600px) {
    .modal {
        max-width: 95vw;
        max-height: 90vh;
    }
    .modal img {
        width: 50vw;
    }
    
}
@media screen and (max-width: 500px) {
    

    .modal {
        padding: 15px;
    }
    .modal-buttons-div {
        padding: 20px 0 0 0;
        justify-content: space-around;
    }

    
}

@media screen and (max-width: 300px) {
    .modal p {
        font-size: 10px;
    }
    .modal-buttons-div button {
        width: 200px;
        font-size: small;
    }
}