

.projects {
    display: block;
    background-color: rgb(255, 255, 255);
    text-align: center;
    overflow: hidden;
}

.projects-inner {
    text-align: center;
    padding-bottom: 7rem;
    overflow: hidden;
}

.projects h1 {
    font-size: 15vw;
    color: rgb(242, 242, 242);
    letter-spacing: 25px;
    padding-left: 12px;
}


.projects-container {
    color: rgb(52, 52, 52);
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    padding: 2rem 3rem 3rem 3rem;
    width: 80%;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
}

.card-div img {
    width: 350px;
    aspect-ratio: 3/2;
    margin: 50px 25px 0 25px;
    border-radius: 30px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.212);
    transition: all ease-in-out 0.3s;
}

.card-div img:hover {
    box-shadow: 0px 0px 50px 5px rgb(0, 127, 206);
}


@media screen and (max-width: 1700px) {
    .projects-container {
        width: 100%;
        padding: 2rem 0 0 0;
    }
}
@media screen and (max-width: 900px) {
    .card-div img {
        width: 70vw;
    }
    .projects-inner {
        padding-bottom: 3;
    }
    .projects h1 {
        letter-spacing: 7px;
    }
}

@media screen and (max-width: 700px) {
    

    .projects-inner {
        padding-bottom: 0;
    }
    .projects-container {
        padding-bottom: 3rem;
    }
    
}