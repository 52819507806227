

.footer {
    width: 100%;
    background-color: rgb(0, 0, 0);
    padding: 3rem;
    display: flex;
    justify-content: space-evenly;
    align-items: top;
    color: rgb(0, 0, 0);;
    flex-wrap: wrap;
    text-align: center;
    overflow: hidden;
}

/* Hides the mobile view contact icons untill the view width in less then 1000px */
.footer-mobile-view {
    display: none;
    margin-top: 2rem;
}

.footer-div {
    display: block;
    margin: 10px;
    padding: 1rem;
    text-align: center;
    color: white;
}

.mobile-contacts-container {
    width: 100%;
    background-color: rgb(0, 0, 0);
    padding: 1rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: rgb(0, 0, 0);
    flex-wrap: wrap;
}

.footer-mobile-view img {
    width: 14vw;
}

.footer-contacts-div h1, .footer-links-div h1, .footer-mobile-view h1 {
    color: rgb(0, 127, 206);
}

.footer-links-div h1 {
    margin-bottom: 40px;
}

.footer-links-div {
    display: grid;
    justify-content: center;
    align-items: center;
}

.footer-links-div button {
    padding: 1rem;
    background-color: rgb(0, 0, 0);
    margin-bottom: 20px;
    border-radius: 30px;
    border: 1px solid rgb(0, 127, 206);
    color: white;
    font-size: large;
    width: 300px;
    cursor: pointer;
    transition: all ease-in 0.1s;
}

.footer-links-div button:hover {
    scale: 1.1;
    background: rgb(0, 157, 255);
    color: rgb(255, 255, 255);
    border: 1px solid white;
}


.contacts {
    margin: 10px;
    color: white;
}

.footer-contacts-div h1 {
    margin-bottom: 40px;
}

.contacts a:link, .footer a:link, .footer a:visited {
    color: white;
}


@media screen and (max-width: 1000px) {
    .footer-mobile-view {
        display: block;
    }
    
    .footer-contacts-div {
        display: none;
    }
    .footer {
        display: block;
    }
}

@media screen and (max-width: 500px) {
    .footer-link {
        width: 60vw;
    }
    .footer-div {
        word-break: break-all;
    }
    .footer {
        padding: 3rem 0;
        word-break: break-all;
    }

    .footer-links-div button {
        font-size: small;
        width: auto;
        padding: 1rem 2rem;
    }

    .footer-link {
        font-size: medium;
        padding: 10px;
    }
    .contacts {
        font-size: smaller;
        
    }
}