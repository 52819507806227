

.skills {
    width: 100%;
    display: block;
    background-color: rgb(247, 247, 247);
    padding: 0rem 3rem 7rem 3rem;
    text-align: center;
    overflow: hidden;
}

.skills h1 {
    font-size: 15vw;
    color: rgba(255, 255, 255, 0.877);
    letter-spacing: 25px;
    padding-left: 25px;
    padding-bottom: 2rem;
}

.icons-div {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    width: 85%;
    margin: 0 auto;
}

.icons-div img {
    width: 150px;
    object-fit: contain;
    aspect-ratio: 3/2;
}
.skills-container {
    width: 90%; 
    display: inline-block;
    justify-content: center;
    align-items: center;
    text-align: left;
    margin: 0 auto;
    background-color: rgba(182, 186, 185, 0.237);
    padding: 2rem;
    border-radius: 30px;
    text-align: center;
}

.skills-container h3 {
    font-weight: 500;
    word-spacing: 3px;
}

.skills-container p {
    word-spacing: 7px;
    line-height: 28px;
}

.skills-list {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: top;
}

.skills-list ul {
    padding: 1rem 3rem;
    text-align: left;
}



@media screen and (max-width: 1000px) {
    .skills-list {
        display: block;
    }
    .icons-div {
        padding: 1rem 0;
        margin: 0;
        width: 100%;
        
    }
    .icons-div img {
        width: 12vw;
    }
}


@media screen and (max-width: 700px) {
    .skills {
        padding: 0 0 2rem 0;
        background-color: rgb(245, 245, 245);
    }
    .skills-container {
        width: 100%;
        border-radius: 0;
        background-color: rgb(245, 245, 245);
        padding: 0 10px;
    }
    .icons-div img {
        width: 100px;
    }
    
    .skills h1 {
        color: rgb(255, 255, 255);
    }
}
@media screen and (max-width: 600px) {
    .skills-list li {
        font-size: smaller;
    }
    .skills-list ul {
        padding: 1rem;
        list-style: inside;
    }
}

@media screen and (max-width: 500px) {
    
    .s
    .skills-container h3 {
        padding: 0 1rem;
    }

    .skills-container h3 {
        font-size: medium;
    }
    
}