* {
  margin: 0;
  box-sizing: border-box;
  padding: 0;
  font-family: 'Aboreto', cursive;
  
}

/* italiana font family abor*/

P {
  font-family: 'Barlow Condensed', sans-serif;
  letter-spacing: 0.2px;
  word-spacing: 1px;
  line-height: 20px;
}

